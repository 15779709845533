<template>
  <div class="page">
    <el-row :gutter="10">
      <el-col :span="showList ? 3 : 1" :class="['p_r', showList ? '' : 'el-col-unfold','leftHeighe']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <el-table
            v-show="showList"
            :data="catalogueList"
            ref="catalogueTable"
            size="small"
            :show-header=false
            height="calc(100vh - 124px)"
            highlight-current-row
            @current-change="directorySelection">
          <el-table-column prop="total" label="资源鉴定"></el-table-column>
        </el-table>
      </el-col>
      <el-col :span="showList ? 21 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="submitForm()">
          <el-form-item prop="name" label="关键字查询：">
            <el-input maxlength="66" clearable v-model.trim="searchForm.name"
                      placeholder="请输入资源名称或者编号"></el-input>
          </el-form-item>
          <el-form-item prop="auditState" label="资源状态：">
            <el-select v-model="searchForm.auditState" clearable placeholder="请选择资源状态">
              <el-option
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-for="item in auditStateList">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="name" label="日期：">
            <el-date-picker
                v-model="searchForm.searchDates"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm()" icon="el-icon-search">查询</el-button>
            <el-button @click="resetForm()" icon="el-icon-refresh-right">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="bg-white">
          <div class="flex_b_c">
            <AdvancedQuery ref="advancedQuery" :moduleId="threeModuleId"
                           @getDataList="atlGetDataList"></AdvancedQuery>
            <div>
              <el-button size="small" plain icon="el-icon-cpu" v-show="hasPermission('admin:resource:handle')"
                         @click="intelligentProcessing">智能处理
              </el-button>
              <el-button size="small" plain icon="el-icon-position" v-show="hasPermission('admin:resource:appraisal')"
                         @click="submitForAppraisal()">提交审核
              </el-button>
              <el-dropdown class="m_r1 m_l1">
                <el-button size="small" plain icon="el-icon-news">智能操作</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-show="hasPermission('admin:resources:uploadForm')" @click.native="uploadForm">
                    上传表格
                  </el-dropdown-item>
                  <el-dropdown-item v-show="hasPermission('admin:resources:downloadForms')"
                                    @click.native="downloadForm">下载表格
                  </el-dropdown-item>
                  <el-dropdown-item v-show="hasPermission('admin:resources:downloadFiles')"
                                    @click.native="downloadFile">下载文件
                  </el-dropdown-item>
                  <el-dropdown-item v-show="hasPermission('admin:resources:dataMatching')" @click.native="dataMatching">
                    数据匹配
                  </el-dropdown-item>
                  <el-dropdown-item v-show="hasPermission('admin:resources:del')" @click.native="del()">批量删除
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
            </div>
          </div>
          <el-table
              :data="threeDimensionalDataList"
              v-loading="threeDimensionalLoading"
              size="small"
              ref="rightList"
              height="calc(100vh - 379px)"
              @selection-change="threeSelectionChangeHandle"
              class="table" row-key='id'>
            <el-table-column
                type="selection"
                width="55" :reserve-selection='true'>
            </el-table-column>
            <el-table-column prop="thumb_path" width="110" label="缩略图">
              <template slot-scope="scope">
                <el-image class="infoImg" :src="scope.row.thumb_path" fit="contain">
                  <el-image slot="placeholder" fit="contain" class="infoImg"
                            :src="require('@/assets/img/thumbnail.png')"></el-image>
                  <el-image slot="error" fit="contain" class="infoImg"
                            :src="require('@/assets/img/thumbnail.png')"></el-image>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
                v-for="item in tableHead"
                :prop="item.fieldEname"
                :key="item.id"
                show-overflow-tooltip
                sortable
                min-width="150"
                :label="item.fieldName">
              <template slot-scope="scope">
                                            <span v-if="item.fieldEname=='resource_type'">
                                                {{ $dictUtils.getDictLabel("resource_type", scope.row.resource_type, '-') }}
                                            </span>
                <span v-else-if="item.fieldEname=='resource_state'">
                                                {{ $dictUtils.getDictLabel("resource_state", scope.row.resource_state, '-') }}
                                            </span>
                <span v-else-if="item.fieldEname=='update_user'">
                                            {{ scope.row.updateUserName }}
                                    </span>
                <span v-else-if="item.fieldEname=='create_user'">
                                            {{ scope.row.createUserName }}
                                    </span>
                <span v-else>{{ scope.row[item.fieldEname] }}</span>
              </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                width="250"
                label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small"
                           @click="see(scope.row)">详情
                </el-button>
                <el-button type="text" size="small"
                           @click="edit(scope.row)">修改
                </el-button>
                <el-button type="text" size="small" @click="auditLog(scope.row.id)"
                           v-if="scope.row.resource_state == '4'">审核记录
                </el-button>
                <el-button type="text" size="small"
                           @click="del($event,scope.row.id)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              @size-change="threeSizeChangeHandle"
              @current-change="threeCurrentChangeHandle"
              :current-page="threePageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="threePageSize"
              :total="threeTotal"
              background
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <!--列表设置拖拽排序弹窗-->
    <DraggablePop :moduleId="threeModuleId" :setShow="threeSetShow"
                  @getTbList="getTbList"></DraggablePop>
    <!--上传表格-->
    <BulkImport ref="bulkImport" @refreshDataList="getFileList()" :originalId="metadataId" :resourceType="threeType"
                :resourceState="1"
                :resourceName="resourceName"></BulkImport>
    <!--数据匹配-->
    <DataMatching ref="dataMatching" @refreshDataList="getFileList()"></DataMatching>
    <!--智能处理-->
    <intelligentManagement @Administration="Administration" :handleDialogVisible="handleDialogVisible"
                           :zhiengchuli="zhiengchuli"></intelligentManagement>
    <!--        三维-->
    <!--查看-->
    <ViewThreeForm ref="viewThreeForm" @refreshDataList="getFileList"></ViewThreeForm>
    <!--编辑-->
    <EditThreeForm ref="editThreeForm" @refreshDataList="getFileList"></EditThreeForm>

    <!--        其它-->
    <!--编辑-->
    <EditForm ref="editForm" @refreshDataList="getFileList"></EditForm>
    <!--查看-->
    <ViewForm ref="viewForm"></ViewForm>
    <AuditLog ref="auditLog"></AuditLog>
  </div>
</template>

<script>
import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
import BulkImport from '../gather/resource/components/bulkImport' // 上传表格弹窗
import DataMatching from '../gather/resource/components/dataMatching' // 数据匹配弹窗
import intelligentManagement from './intelligence/intelligentManagement' // 智能处理弹窗
import EditThreeForm from '../gather/resource/three/editThreeForm' // 编辑弹窗
import ViewThreeForm from '../gather/resource/three/viewThreeForm' // 三维查看弹窗
import AddForm from '../gather/resource/components/addForm' // 新增弹窗
import EditForm from '../gather/resource/components/editForm' // 编辑弹窗
import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
import AuditLog from './auditLog'

export default {
  name: "resourceCataloging",
  components: {
    AuditLog,
    DraggablePop,
    AdvancedQuery,
    BulkImport,
    intelligentManagement,
    DataMatching,
    EditThreeForm,
    ViewThreeForm,
    AddForm,
    EditForm,
    ViewForm
  },
  data() {
    return {
      auditStateList: [
        {
          value: 1,
          label: "资源编目"
        }, {
          value: 4,
          label: "资源驳回"
        }
      ],
      highData: null,
      showList: true,
      searchTpye: 0,
      threeType: 0,
      tableHead: [],
      searchForm: {
        name: '',
        auditState: '',
        searchDates: ['', ''],
      },
      threeDimensionalDataList: [],
      threeDimensionalLoading: false,
      threeDataListSelections: [],
      threePageNo: 0,
      threePageSize: 10,
      threeTotal: 0,
      threeModuleId: "948974400588218368",   // 当前请求拖拽排序数据id
      threeSetShow: false,
      handleDialogVisible: false,
      zhiengchuli: [],
      metadataId: '',//元数据id
      resourceName: "资源采集/上传表格",
      catalogueList: [
        {
          value: 0,
          total: '三维资源',
        },
        {
          value: 1,
          total: '图片资源',
        },
        {
          value: 2,
          total: '音频数据',
        },
        {
          value: 3,
          total: '视频数据',
        },
        {
          value: 4,
          total: '出版物数据',
        },
        {
          value: 5,
          total: '文献数据',
        },
        {
          value: 6,
          total: '文件资料',
        },
        {
          value: 7,
          total: '其它资源',
        },
      ],
      log: {
        operModular: "2",//模块
        operType: "3",//类型
        operTerm: "",//内容
        operSystem: "0",//结果
      },
      listType: '',
      onScene: false, // 是否正在使用场景
      dataListQuery: [], // 临时储存场景
    }
  },
  mounted() {
    this.autoHighlight();
    this.getFileList();
    this.getHead();
  },
  methods: {
    //获取表头数据
    getHead() {
      this.tableHead = [];
      this.$axios(this.api.sys.getByOriginalId + this.threeModuleId, '', "get").then((res) => {
        if (res.status) {
          this.tableHead = res.data.filter(item => item.logicType == 0)
          this.$nextTick(() => {
            this.$refs.rightList.doLayout()
          })
        }
      })
    },
    //获取资源列表
    //type: -1 时为重置不需要考虑高级筛选
    getFileList(type) {
      if (!this.searchForm.searchDates) {
        this.searchForm.searchDates = ['', '']
      }
      if (type != -1) {
        if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
          this.getDataList(this.dataListQuery)
          this.loading = false
          return
        }
      }
      if(type == 2) {
        this.dataListSelections = []
        this.$refs.rightList.clearSelection()
      }
      this.threeDimensionalLoading = true;
      this.dataListQuery.sceneId = ''
      this.$axios(this.api.resources.querycatalogingList, {
        'current': this.threePageNo,
        'size': this.threePageSize,
        'delFlag': 0,
        'type': this.threeType,
        'status': this.searchForm.auditState,
        'name': this.searchForm.name,
        'startTime': this.searchForm.searchDates[0],
        'endTime': this.searchForm.searchDates[1]
      }, 'get').then(res => {
        if (res.status) {
          this.threeDimensionalLoading = false;
          this.threeDimensionalDataList = res.data.records;
          this.threeTotal = parseInt(res.data.total);
        }
      })
    },

    //获取元数据id
    getMetadataId() {
      this.$axios(this.api.original.metaDataDirectoryList, {
        'resourceName': this.resourceName,
        'current': this.threePageNo,
        'size': this.threePageSize,
      }, 'get').then(data => {
        if (data.status) {
          this.metadataId = data.data.records[0].id;
        }
      })
    },

    //左边目录页默认高亮第一行
    autoHighlight() {
      this.$refs.catalogueTable.setCurrentRow(this.catalogueList[0])
    },

    //左边列表选择
    directorySelection(news) {
      this.threePageNo = 1;
      this.threeTotal = 0;

      this.onScene = false
      this.dataListQuery.queryConditions = []
      this.searchForm.name = ''
      this.threePageNo = 0
      this.searchForm.searchDates = ['', '']
      this.threeType = news.value
      this.listType = news.value
      this.getFileList();
      this.getMetadataId();
    },

    //重置
    resetForm() {
      this.dataListSelections = []
      this.$refs.rightList.clearSelection()
      this.searchForm.name = ''
      this.searchForm.auditState = ''
      this.searchForm.searchDates = ['', '']
      this.dataListQuery = []
      this.$refs.advancedQuery.addForm.sceneId = ''
      this.$nextTick(() => {
        this.onScene = false
        this.pageNo = 0
        this.getFileList(-1)
      })
    },

    //查询
    submitForm() {
      this.threePageNo = 1;
      this.threeTotal = 0;
      this.getFileList();
    },

    //智能处理
    intelligentProcessing() {
      if (this.threeDataListSelections.length == 0) {
        this.$message.warning('请至少选择一条数据')
      } else {
        this.zhiengchuli = [];
        this.zhiengchuli = this.threeDataListSelections;
        this.handleDialogVisible = true;
      }
    },

    //智能处理弹窗关闭
    Administration(num) {
      if (num == 1) {
        this.getFileList();
      }
      this.handleDialogVisible = false;
    },

    //提交审核
    submitForAppraisal(id) {
      if (this.threeDataListSelections.length == 0) {
        this.$message.warning('请至少选择一条数据')
      } else {
        let ids = id || this.threeDataListSelections.map(item => {
          return item.id
        }).join(',')
        this.$confirm('此操作会将选中的数据提交审核, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios(this.api.resources.submitAppraisal, {"ids": ids}, 'post').then(res => {
            if (res.status) {
              this.$message.success('提交审核成功')
            } else {
              this.$message.error('提交审核失败')
            }
            this.getFileList(2);
          })
        })
      }
    },

    // 每页数
    threeSizeChangeHandle(val) {
      this.threePageSize = val
      this.threePageNo = 1
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList();
      }
    },
    // 当前页
    threeCurrentChangeHandle(val) {
      this.threePageNo = val
      if (this.onScene) {
        this.getDataList(this.dataListQuery)
      } else {
        this.getFileList();
      }
    },

    // 多选
    threeSelectionChangeHandle(val) {
      this.threeDataListSelections = val
    },

    //查看
    see(row) {
      if (this.threeType == 0) { //三维
        this.$refs.viewThreeForm.init(row);
      } else {
        this.$refs.viewForm.init(row.id, row.resource_type, this.threeModuleId);
      }
    },

    //编辑
    edit(row) {
      if (this.threeType == 0) {
        this.$refs.editThreeForm.init(row);
      } else {
        this.$refs.editForm.init(row.id, this.listType, this.threeModuleId, row.collection_id, row.resource_type);
      }
    },

    // 审核记录
    auditLog(id) {
      this.$refs.auditLog.init(id,'','',21)
    },

    //删除
    del(event, id) {
      let ids = id || this.threeDataListSelections.map(item => {
        return item.id
      }).join(',')
      if (!id && !this.threeDataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      this.$confirm('此操作会将该的数据删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (id) {
          //鼠标点击按钮后，按钮的默认选中
          let target = event.target;
          if (target.nodeName == "SPAN") {
            target = event.target.parentNode;
          }
          target.blur();
        }
        this.$axios(this.api.resources.remove, {
          'delFlag': 1, 'ids': ids
        }, 'post').then(res => {
          if (res.status) {
            this.$message.success('删除成功')
            this.getFileList(2);
          }
        })
        this.getList();
      }).catch(() => {
      });
    },

    // 上传表格
    uploadForm() {
      this.$refs.bulkImport.init();
    },

    // 数据匹配
    dataMatching() {
      if (!this.threeDimensionalDataList.length) {
        this.$message.warning('暂无数据')
        return
      }
      this.$refs.dataMatching.init('', this.listType);
    },

    //下载表格
    downloadForm() {
      if (!this.threeDataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return;
      }
      let ids = this.threeDataListSelections.map(item => {
        return item.id
      }).join(',')
      this.$axios(this.api.rcPage.exPortList, {ids: ids}, 'get', 'blob').then(res => {
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
        // let filename = res.headers['content-disposition']
        let filename = '表格';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })
    },

    //下载文件
    downloadFile() {
      if (!this.threeDataListSelections.length) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      let ids = this.threeDataListSelections.map(item => {
        return item.id
      }).join(',')
      this.$message.info('文件已开始下载，请勿重复操作')
      this.$axios(this.api.rcPage.downloadFile, {ids: ids, module: 1}, 'get', 'blob').then(res => {
        let blob = new Blob([res.data], {type: 'application/zip'})
        // let filename = res.headers['content-disposition']
        let filename = '文件';
        let link = document.createElement('a')
        link.download = decodeURI(filename)
        link.href = window.URL.createObjectURL(blob)
        link.click()
      })

    },
    // 高级查询
    atlGetDataList(data) {
      this.threePageNo = 0
      this.getDataList(data)
    },

    // 获取高级查询条件
    getDataList(data) {
      if (data != null && data.sceneId != null && data.sceneId != '') {
        this.onScene = true
      } else {
        this.onScene = false
      }
      if (data.clearCurrent != null && data.clearCurrent == true) {
        this.pageNo = 0
      }
      this.dataListQuery = data
      this.searchTpye = 0
      data.size = this.threePageSize
      data.current = this.threePageNo
      data.type = this.threeType
      data.resourceState = this.searchForm.auditState
      data.delFlag = 0
      data.searchNumOrName = this.searchForm.name
      data.startTime = this.searchForm.searchDates[0]
      data.endTime = this.searchForm.searchDates[1]
      this.$axios(this.api.rcPage.catalogueQueryResource, JSON.stringify(data), 'post').then(res => {
        if (res && res.status) {
          this.threeDimensionalDataList = res.data.records
          this.threeTotal = parseInt(res.data.total);
        }
      })
    },

    // 设置
    setTb() {
      this.threeSetShow = true;
    },

    // 接收子组件（排序弹窗）传过来的值
    getTbList(data, state) {
      this.dataList = data;
      this.getHead();
      this.threeSetShow = state;
    },

  },
}
</script>

<style scoped>
.leftHeighe{
  height: calc(100vh - 124px);
}
</style>
